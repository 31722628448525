html,
body {
  /* fix headlessui issue adding 15px padding-right opening menus */
  padding: 0 !important;
  margin: 0;
  font-family: "lato";
}

html,
body,
#__next {
  width: 100%;
  /* height: 100%;   */
  display: flex; /* for robot */
  justify-content: center; /* for robot */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-Regular.ttf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-Italic.ttf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-Black.ttf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-BlackItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-Bold.ttf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-BoldItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-Light.ttf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-LightItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-Thin.ttf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-ThinItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: 100;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-arrow {
  -moz-appearance: textfield;
}

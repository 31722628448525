@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-beige: #f2ede9;
    --color-red: #f76c6c;
    --color-warning: #fb923c;
    --color-negative: #e20910;
    --color-positive: #507d03;
    --color-yellow: #ffbd01;
    --color-yellowLight: #ffebb2;
    --color-yellowLighter: rgb(254 249 195);
    --color-stone: rgb(119, 117, 116);
    --color-slate: rgb(241 245 249);
    --color-lime: rgb(101 163 13);
    --color-green: #507d03;
    --color-greenDark: #136773;
    --color-greenBar: #50c878;
    --color-blue: #004c77;
    --color-blueLight: #4a90e2;
    --color-blueDark: #02304b;
    --color-blueHover: #f0f8ff;
    --color-blueHighcharts: #7cb5ec;
    --color-blueHighlighted: #0066ff;
    --color-grey: #e8e8e8;
    --color-greyLight: #f7f8f9;
    --color-greyDark: #2a2a2a;
    --color-greyBorder: #d3d3d3;
    --color-greyBackground: #f5f5f5;
    --color-greyShadow: #c5c5c5;
    --color-greyBoxBackground: #d3f6fa;
    --color-defaultText: #02304b;
    --color-greenHighlighted: #bef264;
  }

  .theme_default {
    --color-primary: #02304b;
    --color-secondary: #f76c6c;
    --color-headerBackground: #ffffff;
    --color-headerMenuText: #02304b;
    --color-inverseMenuBackground: #02304b;
    --color-inverseMenuText: #ffffff;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #02304b;
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_norne {
    --color-primary: #02304b;
    --color-secondary: #f76c6c;
    --color-headerBackground: #02304b;
    --color-headerMenuText: #ffffff;
    --color-inverseMenuBackground: #ffffff;
    --color-inverseMenuText: #02304b;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #02304b;
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_fanaSparebank {
    --color-primary: #283593;
    --color-secondary: #ff8500;
    --color-headerBackground: #ffffff;
    --color-headerMenuText: #383838;
    --color-inverseMenuBackground: #283593;
    --color-inverseMenuText: #ffffff;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #2a2a2a;
    --color-robotHeaderBackground: #283593;
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_eika {
    --color-primary: #90d000;
    --color-secondary: #004f59;
    --color-headerBackground: #ffffff;
    --color-headerMenuText: #00383d;
    --color-inverseMenuBackground: #90d000;
    --color-inverseMenuText: #00383d;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #90d000;
    --color-robotHeaderBackgroundText: #00383d;
  }

  .theme_sparebankenSognOgFjordane {
    --color-primary: #00529b;
    --color-secondary: #660539;
    --color-headerBackground: #ffffff;
    --color-headerMenuText: #000000;
    --color-inverseMenuBackground: #00529b;
    --color-inverseMenuText: #ffffff;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #00529b;
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_sparebankenMøre {
    --color-primary: #0066ff;
    --color-secondary: #0066ff;
    --color-headerBackground: #ffffff;
    --color-headerMenuText: #01052f;
    --color-inverseMenuBackground: #0066ff;
    --color-inverseMenuText: #ffffff;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #0066ff;
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_obosBank {
    --color-primary: #0047ba;
    --color-secondary: #00524c;
    --color-headerBackground: #0047ba;
    --color-headerMenuText: #ffffff;
    --color-inverseMenuBackground: #ffffff;
    --color-inverseMenuText: #0047ba;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #0047ba;
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_sparebankenVest {
    --color-primary: #222f49;
    --color-secondary: rgb(216, 59, 59);
    --color-headerBackground: #ffffff;
    --color-headerMenuText: #02304b;
    --color-inverseMenuBackground: #222f49;
    --color-inverseMenuText: #ffffff;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: rgb(216, 59, 59);
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_sparebankenØst {
    --color-primary: #002d4b;
    --color-secondary: #ffcd00;
    --color-headerBackground: #002d4b;
    --color-headerMenuText: #ffffff;
    --color-inverseMenuBackground: #ffffff;
    --color-inverseMenuText: #002d4b;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #002d4b;
    --color-robotHeaderBackgroundText: #ffffff;
  }

  .theme_aasenSparebank {
    --color-primary: #8abd24;
    --color-secondary: #323e48;
    --color-headerBackground: #ffffff;
    --color-headerMenuText: #02304b;
    --color-inverseMenuBackground: #8abd24;
    --color-inverseMenuText: #02304b;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #8abd24;
    --color-robotHeaderBackgroundText: #000000;
  }

  .theme_sekstiAatteGraderNord {
    --color-primary: #00353d;
    --color-secondary: #71b790;
    --color-headerBackground: #00353d;
    --color-headerMenuText: #ffffff;
    --color-inverseMenuBackground: #ffffff;
    --color-inverseMenuText: #00353d;
    --color-accountMenuText: #ffffff;
    --color-menuHover: #e8e8e8;
    --color-robotHeaderBackground: #00353d;
    --color-robotHeaderBackgroundText: #ffffff;
  }
}

.infront .cell-tbl-change-bars--down {
  background-color: #e20910;
}

.infront .cell-tbl-change-bars--no-change {
  background-color: #e8e8e8;
}

.infront .cell-tbl-change-bars--up {
  background-color: #507d03;
}

.infront .cell-status-positive {
  color: #507d03 !important;
}

.infront .cell-status-negative {
  color: #e20910 !important;
}

.infront .cell-update-down {
  background-color: #e20910 !important;
  color: white !important;
}

.infront .cell-update-up {
  background-color: #507d03 !important;
  color: white !important;
}

.infront table {
  font-size: 12px;
}

.infront .cell-h4,
.infront .cell-table tr th {
  color: #02304b;
}

/* Remove text-transform from all headings */
.infront .cell-tabs__horizontal__tab,
.infront .cell-table th,
.infront .cell-h4,
.infront .cell-w-index-overview--mini__name {
  text-transform: none;
}

/* dropdown button */
.infront .cell-mininav__btn {
  padding: 4px 20px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #e8e8e8;
  font-size: 12px;
  color: #02304b;
  height: 24px;
}

.infront .cell-mininav__btn:hover {
  background-color: #e8e8e8;
}

/* Dropdown styling */
.cell-custom-dropdown-selector-wrapper {
  min-width: 300px !important;
  border-radius: 10px;
  color: #02304b;
}

.cell-search-result__wrapper {
  width: auto !important;
}

.cell-custom-dropdown-selector {
  border-radius: 10px;
}

.cell-custom-dropdown-selector__item__label::after,
.cell-custom-dropdown-selector__item__label--selected::after {
  display: none;
}

.infront .market-index .cell-w-index-overview--mini__abs-change,
.infront .market-index .cell-w-index-overview--mini__last {
  line-height: 12px;
  font-size: 12px;
}

.infront .market-index .cell-w-index-overview--mini__pct-change {
  font-size: 16px;
}

/* hack to show dividend, and hide other history tables */
#dividend .cell-form-elements {
  display: none;
}

#dividend table.cell-table:nth-child(3) {
  display: none !important;
}

#dividend table.cell-table:nth-child(4) {
  display: table !important;
  width: 100%;
}

#dividend table.cell-table:nth-child(4) tbody {
  display: block;
  max-height: 250px;
  overflow-y: scroll;
  width: 100%;
}

#dividend table.cell-table:nth-child(4) tr {
  display: inline-block;
  width: 100%;
}

#dividend table.cell-table:nth-child(4) td,
#dividend table.cell-table:nth-child(4) th {
  display: inline-block;
  width: 50%;
}

/* dividend end */

/* topInvestors start */
#topInvestors .cell-table {
  display: table !important;
}

#topInvestors .cell-table tbody {
  display: block !important;
  max-height: 250px;
  overflow-y: auto;
}

#topInvestors .cell-table tr,
#topInvestors .cell-table tbody,
#topInvestors .cell-table thead {
  display: table;
  table-layout: fixed;
  width: 100%;
}

#topInvestors .cell-table thead {
  margin-right: 20px;
}

#topInvestors .cell-table .cell-table__txt--investor {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
}

#topInvestors .cell-table .cell-table__num--rank {
  width: 15px;
}

#topInvestors .cell-table .cell-table__txt--positions {
  display: none;
}

/* topInvestors end */

#instrumentOverview .cell-flex-row {
  column-gap: 20px;
}

#instrumentOverview .cell-w-instrument-overview__thirdrow,
#instrumentOverview .cell-table--instrument-overview,
#instrumentOverview .cell-w-instrument-overview__labels,
#instrumentOverview .cell-w-instrument-overview-bar,
#instrumentValues .cell-h4 {
  display: none;
}

#instrumentValues .cell-table th,
#instrumentValues2 .cell-table th {
  border-bottom: none;
  font-weight: 400;
}

#instrumentValues .cell-table td,
#instrumentValues2 .cell-table td {
  border-bottom: none;

  @media only screen and (min-width: 600px) {
    font-weight: 700;
    font-size: larger;
  }
}

#instrumentValues th.cell-table__num--ask--header,
#instrumentValues td.cell-table__num--ask,
#instrumentValues th.cell-table__num--low--header,
#instrumentValues td.cell-table__num--low {
  border-right: 1px solid #cccccc;
}

#instrumentValues th.cell-table__num--change--header,
#instrumentValues td.cell-table__num--change {
  @media only screen and (min-width: 1024px) {
    border-right: 1px solid #cccccc;
  }
}

#instrumentValues th.cell-table__num--bid--header,
#instrumentValues td.cell-table__num--bid {
  @media only screen and (min-width: 1024px) {
    border-left: 1px solid #cccccc;
  }
}
.infront .favoriteStar {
  color: #f5c518;
}

.infront #myStocks tbody {
  display: block;
  max-height: 270px;
  overflow-y: auto;
  width: 100%;
  table-layout: fixed;
}

.infront #myStocks tr {
  display: grid;
  grid-template-columns: 0.2fr 0.2fr repeat(7, 0.6fr);
  width: 100%;
  table-layout: fixed;
}

.infront #favoriteStocks tbody {
  display: block;
  max-height: 270px;
  overflow-y: auto;
  width: 100%;
  table-layout: fixed;
}

.infront #favoriteStocks tr {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.2fr;
  width: 100%;
  table-layout: fixed;
}

.cell-overlay-lightbox__close-btn {
  background-color: #fff !important;
}

.cell-overlay-lightbox__close-btn:hover {
  background-color: #e8e8e8 !important;
}

.cell-overlay-lightbox__close-btn:after {
  content: "\00d7" !important;
  margin-left: 5px !important;
  background-color: inherit !important;
  position: static !important;
  -webkit-mask: none !important;
  mask: none !important;
}
